import type {Analytics} from '../../core/Analytics';
import {Event} from '../../enums/Event';
import {Player} from '../../enums/Player';
import {StreamTypes} from '../../enums/StreamTypes';
import {ErrorDetailBackend} from '../../features/errordetails/ErrorDetailBackend';
import {ErrorDetailTracking} from '../../features/errordetails/ErrorDetailTracking';
import type {Feature} from '../../features/Feature';
import type {FeatureConfig} from '../../features/FeatureConfig';
import type {AnalyticsStateMachineOptions} from '../../types/AnalyticsStateMachineOptions';
import type {CodecInfo} from '../../types/CodecInfo';
import type {FeatureConfigContainer} from '../../types/FeatureConfigContainer';
import type {PlaybackInfo} from '../../types/PlaybackInfo';
import type {QualityLevelInfo} from '../../types/QualityLevelInfo';
import {logger} from '../../utils/Logger';
import {HTML5InternalAdapter} from '../html5/HTML5InternalAdapter';
import type {InternalAdapterAPI} from '../internal/InternalAdapterAPI';

import {ShakaPlayerEvent} from './shakaPlayerTypes';

export class ShakaInternalAdapter extends HTML5InternalAdapter implements InternalAdapterAPI {
  constructor(
    private player: any,
    opts?: AnalyticsStateMachineOptions,
  ) {
    super(player.getMediaElement(), opts);
  }

  override initialize(analytics: Analytics): Array<Feature<FeatureConfigContainer, FeatureConfig>> {
    super.initialize(analytics);
    let videoElement: HTMLVideoElement | undefined;
    try {
      // returns null if content was not loaded yet
      // @see https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#getMediaElement
      videoElement = this.player.getMediaElement();
    } catch (_e) {
      // no action needed, because we will throw error later in code, because of video element null check
    }

    if (videoElement != null) {
      this.setMediaElement(videoElement);
    } else {
      // if we don't have video/media element from shaka player during initialization (shaka behaviour:
      // it has not loaded content yet), we need to wait for the `media-source` state change event and
      // then try to get the media element.
      const handleOnStateChangeAndSetMediaElement = (event: {state: string}) => {
        if (event.state === 'media-source') {
          try {
            this.player.removeEventListener(ShakaPlayerEvent.ONSTATECHANGE, handleOnStateChangeAndSetMediaElement);
            this.setMediaElement(this.player.getMediaElement());
          } catch (e) {
            logger.errorMessageToUser(
              'Something went wrong while getting underlying HTMLVideoElement. Not possible to attach adapter and initialize Bitmovin Analytics. Error: ',
              e,
            );
            this.release();
          }
        }
      };

      this.player.addEventListener(ShakaPlayerEvent.ONSTATECHANGE, handleOnStateChangeAndSetMediaElement);
    }

    // With shaka a qualitychange only triggers a rebuffering, so to catch a qualitychange in time, we need to check qualityinfo on bufferings as well.
    this.player.addEventListener(ShakaPlayerEvent.BUFFERING, (_) => {
      this.checkQualityLevelAttributes();
    });

    this.player.addEventListener(ShakaPlayerEvent.ONSTATECHANGE, (event: {state: string}) => {
      if (event.state === 'detach') {
        // Detach the player from the current media element. Leaves the player in a state where
        // it cannot play media, until it has been attached to something else.
        this.eventCallback(Event.UNLOAD, {});
        this.release();
      }
    });

    const errorDetailTracking = new ErrorDetailTracking(
      analytics.errorDetailTrackingSettingsProvider,
      new ErrorDetailBackend(analytics.errorDetailTrackingSettingsProvider.collectorConfig),
      [analytics.errorDetailSubscribable],
      undefined,
    );
    return [errorDetailTracking];
  }

  override getPlayerName = () => Player.SHAKA;

  getPlayerVersion = () => this.player.constructor.version;

  override isLive = () => {
    return this.player ? this.player.isLive() : undefined;
  };

  override getCurrentPlaybackInfo(): PlaybackInfo {
    const playbackInfo: PlaybackInfo = {
      ...super.getCurrentPlaybackInfo(),
    };

    const codecInfo = getShakaCodecInfo(this.player);
    this.sourceInfoFallbackService.applyAndStoreCodecInfo(playbackInfo, codecInfo);

    return playbackInfo;
  }

  override getStreamType(): string | undefined {
    return getShakaStreamFormat(this.player);
  }

  override getStreamURL() {
    return this.player ? this.player.getAssetUri() : null;
  }

  override getCurrentQualityLevelInfo(): null | QualityLevelInfo {
    const variantTracks = this.player.getVariantTracks();

    const activeVideoTrack = variantTracks
      .filter((track: any) => track.active)
      .filter((track: any) => track.videoCodec || track.videoId !== undefined)[0];

    if (!activeVideoTrack) {
      // can only happen for audio-only streams
      return null;
    }

    const bitrate = activeVideoTrack.videoBandwidth || activeVideoTrack.bandwidth;
    const width = activeVideoTrack.width;
    const height = activeVideoTrack.height;

    return {
      bitrate: bitrate,
      width: width,
      height: height,
    };
  }
}

export const getShakaCodecInfo = (player: any): CodecInfo | undefined => {
  const variantTracks = player.getVariantTracks();
  const activeTrack = variantTracks.find((track: any) => track.active);

  return {
    videoCodec: activeTrack?.videoCodec,
    audioCodec: activeTrack?.audioCodec,
  };
};

export const getShakaStreamFormat = (player: any): string | undefined => {
  if (!player) {
    return undefined;
  }

  // `getManifestType` is only available in shaka v4.4.0 and later (we need to support 4.0.0)
  const manifestType = player.getManifestType?.()?.toLowerCase();

  if (manifestType === StreamTypes.dash) {
    return StreamTypes.dash;
  } else if (manifestType === StreamTypes.hls) {
    return StreamTypes.hls;
  }

  // https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html#.LoadMode
  const LOAD_MODE_SRC_EQUALS = 3;

  // Fallback to progressive if no manifest type is available.
  // In order to avoid over-reporting of progressive when the source is not loaded yet
  // (where getManifestType is also null similar to when a progressive source is used)
  // we check that the load mode is SRC_EQUALS which indicates native player usage
  // and make sure that the asset is already loaded through check if the uri is available
  if (manifestType == null && player.getLoadMode() === LOAD_MODE_SRC_EQUALS && player.getAssetUri() != null) {
    return StreamTypes.prog;
  }

  return undefined;
};
